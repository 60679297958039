.boxFilters {
    display: flex;
    flex-direction: row;
}

.box {
  flex: auto;
  height: 10vw;
  width: 2.2vw;
  background-color: #883939;
}
.button-text {
    color: white;
    font-family: 'Indie Flower', cursive;
    font-size: 5vw;
    /* margin: 20px; */
}

.selected {
    background-color: #d32f2f;
}
.itemList {

}
.itemDetail {
  margin: 20px;
  font-family: 'Indie Flower', cursive;
  font-size: 7vw;
}

h3 {
    font-family: 'Permanent Marker', cursive;
    font-size: 1.5em;
}